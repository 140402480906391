/* eslint-disable vue/no-unused-components */
<template>
  <div>
    <div class="contact">
      <div class="description mb-32">
        <h1 class="h1 mb-32">
          {{ $t('contact.headline') }}
        </h1>
        <div class="body text-gray">
          <div class="grid grid-cols-12 gap-24">
            <input-text v-model="contactData.firstName"
                        name="firstName"
                        :invalid="isInvalid('firstName')"
                        :validation="$v.contactData.firstName"
                        :label="$t('contact.firstName')"
                        :placeholder="$t('contact.firstNamePlaceholder')"
                        class="col-span-6"
                        optional
            ></input-text>
            <input-text v-model="contactData.lastName"
                        :invalid="isInvalid('lastName')"
                        :validation="$v.contactData.lastName"
                        name="lastName"
                        :label="$t('contact.lastName')"
                        :placeholder="$t('contact.lastNamePlaceholder')"
                        class="col-span-6"
            ></input-text>
          </div>
          <input-text v-model="contactData.address"
                      name="address"
                      :invalid="isInvalid('address')"
                      :validation="$v.contactData.address"
                      :label="$t('contact.address')"
                      :placeholder="$t('contact.addressPlaceholder')"
                      :optional="!settings.requireAddress"
          />
          <div class="grid grid-cols-12 gap-24">
            <div class="col-span-4">
              <input-text v-model="contactData.zipCode"
                          name="zip"
                          :invalid="isInvalid('zipCode')"
                          :validation="$v.contactData.zipCode"
                          :label="$t('contact.zipCode')"
                          :placeholder="$t('contact.zipCodePlaceholder')"
                          :optional="!settings.requireAddress"
              />
            </div>
            <div class="col-span-8">
              <input-text v-model="contactData.city"
                          name="city"
                          :invalid="isInvalid('city')"
                          :validation="$v.contactData.city"
                          :label="$t('contact.city')"
                          :placeholder="$t('contact.cityPlaceholder')"
                          :optional="!settings.requireAddress"
              />
            </div>
          </div>
          <input-country v-model="contactData.countryCode"
                         name="country"
                         :invalid="isInvalid('countryCode')"
                         :validation="$v.contactData.countryCode"
                         :label="$t('contact.country')"
                         :placeholder="$t('contact.countryPlaceholder')"
                         :optional="!settings.requireAddress"
          />
          <checkbox v-if="settings.askForCompanyName" v-model="contactData.alternateBillingAddress" name="alternateBillingAddress" :title="$t('contact.alternateBillingAddress')" />
          <div v-if="contactData.alternateBillingAddress" class="company-information">
            <hr class="border-t border-1 border-grayLight mb-24 block" />
            <input-text v-model="contactData.companyName"
                        name="companyname"
                        :label="$t('contact.companyName')"
                        :invalid="isInvalid('companyname')"
                        :validation="$v.contactData.companyname"
                        placeholder=""
                        optional
            ></input-text>
            <div class="grid grid-cols-12 gap-24">
              <input-text v-model="contactData.companyFirstName"
                          name="companyFirstName"
                          :invalid="isInvalid('companyFirstName')"
                          :validation="$v.contactData.companyFirstName"
                          :label="$t('contact.companyFirstName')"
                          :placeholder="$t('contact.companyFirstNamePlaceholder')"
                          class="col-span-6"
                          optional
              ></input-text>
              <input-text v-model="contactData.companyLastName"
                          name="companyLastName"
                          :invalid="isInvalid('companyLastName')"
                          :validation="$v.contactData.companyLastName"
                          :label="$t('contact.companyLastName')"
                          :placeholder="$t('contact.companyLastNamePlaceholder')"
                          class="col-span-6"
                          optional
              ></input-text>
            </div>
            <input-textarea v-model="contactData.companyAddress"
                            :invalid="isInvalid('companyAddress')"
                            :validation="$v.contactData.companyAddress"
                            name="companyaddress"
                            :label="$t('contact.companyAddress')"
                            :placeholder="$t('contact.companyAddressPlaceholder')"
                            optional
            ></input-textarea>
            <div class="grid grid-cols-12 gap-24">
              <div class="col-span-4">
                <input-text v-model="contactData.companyZipCode"
                            :invalid="isInvalid('companyZipCode')"
                            :validation="$v.contactData.companyZipCode"
                            name="companyzip"
                            :label="$t('contact.companyZipCode')"
                            :placeholder="$t('contact.companyZipCodePlaceholder')"
                            optional
                ></input-text>
              </div>
              <div class="col-span-8">
                <input-text v-model="contactData.companyCity"
                            :invalid="isInvalid('companyCity')"
                            :validation="$v.contactData.companyCity"
                            name="companycity"
                            :label="$t('contact.companyCity')"
                            :placeholder="$t('contact.companyCityPlaceholder')"
                            optional
                ></input-text>
              </div>
            </div>
            <input-country v-model="contactData.companyCountryCode"
                           :invalid="isInvalid('companyCountryCode')"
                           :validation="$v.contactData.companyCountryCode"
                           name="companycountry"
                           :label="$t('contact.companyCountry')"
                           :placeholder="$t('contact.companyCountryPlaceholder')"
            ></input-country>
          </div>
          <hr class="border-t border-1 border-grayLight mb-24 block" />
          <input-text v-model="contactData.email"
                      type="email"
                      name="email"
                      :invalid="isInvalid('email')"
                      :validation="$v.contactData.email"
                      :label="$t('contact.email')"
                      :placeholder="$t('contact.emailPlaceholder')"
          ></input-text>
          <input-phone v-model="contactData.phoneNumber"
                       name="phone"
                       :invalid="isInvalid('phoneNumber')"
                       :validation="$v.contactData.phoneNumber"
                       :label="$t('contact.phoneNumber')"
                       :placeholder="$t('contact.phoneNumberPlaceholder')"
                       :optional="!settings.requirePhoneNumber"
          ></input-phone>
          <!--          <checkbox name="Newsletter" :title="$t('contact.newsletter')" optional />-->
          <checkbox v-model="contactData.saveContactData"
                    name="saveContactData"
                    :label="$t('contact.saveContactData')"
                    :invalid="isInvalid('saveContactData')"
                    :validation="$v.contactData.saveContactData"
                    :title="$t('contact.saveContactDataCheckbox')"
                    optional
          />
        </div>
        <button-big @click="saveContactData">
          {{ $t('contact.next') }}
        </button-big>
      </div>
    </div>
  </div>
</template>

<script>
import { ClockIcon, InfoIcon } from 'vue-feather-icons';
import InputText from '../InputElements/InputText/InputText';
import InputCountry from '../InputElements/InputCountry/InputCountry';
import InputPhone from '../InputElements/InputPhone/InputPhone';
import Checkbox from '../InputElements/Checkbox/Checkbox';
import ButtonBig from '../ButtonBig/ButtonBig';
import InputTextarea from '@/components/lib/InputElements/InputTextarea/InputTextarea';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import { validationMixin } from 'vuelidate';
import { required, minLength, email, sameAs } from 'vuelidate/lib/validators';
import { cloneDeep, isEmpty, isFunction, isNil } from 'lodash/lang';
import TimerMixin from '@/mixins/TimerMixin';
import scrollIntoView from 'scroll-into-view-if-needed';
import CostSummary from '@/components/lib/Summary/CostSummary';

export default {
  name: 'Contact',
  components: {
    'input-text': InputText,
    'input-textarea': InputTextarea,
    'input-country': InputCountry,
    'input-phone': InputPhone,
    checkbox: Checkbox,
    'button-big': ButtonBig,
    CostSummary,
  },

  mixins: [BookingEngineMixin, TimerMixin, validationMixin],

  validations() {
    const ret = {
      contactData: {
        lastName: {
          required,
          minLength: minLength(2),
        },
        email: {
          required,
          email,
        },
        // saveContactData: {
        //   checked: (value) => value === true,
        // },
      },
    };

    if (this.settings.requireAddress) {
      ret.contactData.city = {
        required,
      };
      ret.contactData.countryCode = {
        required,
      };
      ret.contactData.address = {
        required,
      };

      if (!this.countriesWithoutZipCodes.map((it) => it[1]).includes(this.contactData.countryCode)) {
        ret.contactData.zipCode = {
          required,
        };
      }
    }

    if (this.hotel.details.onlineBookingRequirePhoneNumber) {
      ret.contactData.phoneNumber = {
        required,
        minLength: minLength(5),
      };
    }

    return ret;
  },

  props: {

  },

  data: function () {
    return {
      contactData: {
        firstName: null,
        lastName: null,
        address: null,
        zipCode: null,
        city: null,
        countryCode: null,
        alternateBillingAddress: false,
        companyName: null,
        companyFirstName: null,
        companyLastName: null,
        companyAddress: null,
        companyZipCode: null,
        companyCity: null,
        companyCountryCode: null,
        email: null,
        phoneNumber: null,
      },
      countriesWithoutZipCodes: [
        ['Angola', 'AO'],
        ['Antigua and Barbuda', 'AG'],
        ['Aruba', 'AW'],
        ['Bahamas', 'BS'],
        ['Belize', 'BZ'],
        ['Benin', 'BJ'],
        ['Botswana', 'BW'],
        ['Burkina Faso', 'BF'],
        ['Burundi', 'BI'],
        ['Cameroon', 'CM'],
        ['Central African Republic', 'CF'],
        ['Comoros', 'KM'],
        ['Congo', 'CG'],
        ['Congo, the Democratic Republic of the', 'CD'],
        ['Cook Islands', 'CK'],
        ["Cote d'Ivoire", 'CI'],
        ['Djibouti', 'DJ'],
        ['Dominica', 'DM'],
        ['Equatorial Guinea', 'GQ'],
        ['Eritrea', 'ER'],
        ['Fiji', 'FJ'],
        ['French Southern Territories', 'TF'],
        ['Gambia', 'GM'],
        ['Ghana', 'GH'],
        ['Grenada', 'GD'],
        ['Guinea', 'GN'],
        ['Guyana', 'GY'],
        ['Hong Kong', 'HK'],
        ['Ireland', 'IE'],
        ['Jamaica', 'JM'],
        ['Kenya', 'KE'],
        ['Kiribati', 'KI'],
        ['Macao', 'MO'],
        ['Malawi', 'MW'],
        ['Mali', 'ML'],
        ['Mauritania', 'MR'],
        ['Mauritius', 'MU'],
        ['Montserrat', 'MS'],
        ['Nauru', 'NR'],
        ['Netherlands Antilles', 'AN'],
        ['Niue', 'NU'],
        ['North Korea', 'KP'],
        ['Panama', 'PA'],
        ['Qatar', 'QA'],
        ['Rwanda', 'RW'],
        ['Saint Kitts and Nevis', 'KN'],
        ['Saint Lucia', 'LC'],
        ['Sao Tome and Principe', 'ST'],
        ['Seychelles', 'SC'],
        ['Sierra Leone', 'SL'],
        ['Solomon Islands', 'SB'],
        ['Somalia', 'SO'],
        ['South Africa', 'ZA'],
        ['Suriname', 'SR'],
        ['Syria', 'SY'],
        ['Tanzania, United Republic of', 'TZ'],
        ['Timor-Leste', 'TL'],
        ['Tokelau', 'TK'],
        ['Tonga', 'TO'],
        ['Trinidad and Tobago', 'TT'],
        ['Tuvalu', 'TV'],
        ['Uganda', 'UG'],
        ['United Arab Emirates', 'AE'],
        ['Vanuatu', 'VU'],
        ['Yemen', 'YE'],
        ['Zimbabwe', 'ZW'],
      ],
    };
  },

  computed: {

  },

  watch: {
    contactData: {
      handler: function (newVal) {
        this.$store.commit('contactData', cloneDeep(newVal));
      },
      deep: true,
    },
  },

  created: function () {
    if (isEmpty(this.quoteResponse)) {
      this.$serviceBus.$emit('quote');
    }

    this.contactData.countryCode = this.$store.getters.regionSettings.country;
    this.contactData.companyCountryCode = this.$store.getters.regionSettings.country;
    // this.contactData.phoneNumber = `+${this.$store.getters.regionSettings.callingCode}`;
    this.contactData = {
      ...this.contactData,
      ...this.$store.getters.contactData,
      complete: false,
    };
  },

  mounted: function () {
    this.mounted = true;
    window.scroll({ top: 0, behavior: 'smooth' });
  },

  methods: {
    saveContactData() {
      this.validate();

      if (!this.$v.$invalid) {
        this.contactData.complete = true;
        this.$store.commit('contactData', cloneDeep(this.contactData));
        window.scroll({ top: 0, behavior: 'smooth' });
        this.$router.push({ name: 'summary' });
      }
    },
    validate() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.setTimeout(() => {
          const invalidElement = this.$el.querySelector('label.invalid');
          if (isFunction(invalidElement.scrollIntoView)) {
            scrollIntoView(invalidElement,
              {
                scrollMode: 'if-needed',
                block: 'nearest',
                inline: 'nearest',
                behavior: 'smooth',
              });
          }
        }, 250);
      }
    },
    isInvalid(fieldName) {
      return !isNil(this.$v.contactData[fieldName]) && this.$v.contactData[fieldName].$dirty && this.$v.contactData[fieldName].$invalid;
    },
  },
};
</script>

<style lang="scss">
  @import '~@/styles/import';

</style>
