<template>
  <column2 :class="[{'bg-grayVeryLight': wideEnough}]">
    <template v-if="!isEmpty(selectedOffersMap)">
      <h1 v-show="wideEnough" class="h1 mb-32">
        {{ $t('summary.headline') }}
      </h1>
      <resize-sensor @resize="onresize"></resize-sensor>
      <cost-summary v-show="wideEnough" :short="false" />
    </template>
  </column2>
</template>

<script>
import Column2 from '@/components/lib/Layout/Column2';
import BookingEngineMixin from '@/mixins/BookingEngineMixin';
import CostSummary from '@/components/lib/Summary/CostSummary';
import { isEmpty } from 'lodash/lang';
import ResizeSensor from 'vue-resize-sensor';

export default {
  name: 'ContactColumn2',
  components: { Column2, CostSummary, ResizeSensor },

  mixins: [BookingEngineMixin],

  data: function () {
    return {
      width: 0,
    };
  },

  computed: {
    wideEnough: function () {
      return this.width > (350 + 128);
    },
  },

  methods: {
    isEmpty,
    onresize(event) {
      this.width = event.width;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
