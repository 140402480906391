<template>
  <column1>
    <contact></contact>
  </column1>
</template>

<script>
import AvailabilityCalendar from '@/components/lib/calendar/AvailabilityCalendar';
import Nav from '@/components/lib/Nav/MainNav';
import InfoBox from '@/components/lib/InfoBox/InfoBox';
import Contact from '@/components/lib/Contact/Contact';
import ButtonFloating from '@/components/lib/ButtonFloating/ButtonFloating';
import Column1 from '@/components/lib/Layout/Column1';

export default {
  name: 'ContactView',
  components: {
    Column1,
    'info-box': InfoBox,
    contact: Contact,
  },
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
